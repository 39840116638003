<script setup lang="ts">
import SettingsSidebar from '../Components/atoms/SettingsSidebar.vue';
import { RouterView } from 'vue-router';
import PageTransition from '../Components/transitions/PageTransition.vue';
import { Scrollable } from '@briefcase-app/briefcase-lib';
import { definePage } from 'unplugin-vue-router/runtime';

definePage({
  meta: {
    title: 'Instellingen',
  },
});
</script>

<template>
  <div class="relative flex w-full flex-grow flex-row !p-0">
    <div class="page-sidebar">
      <SettingsSidebar />
    </div>

    <Scrollable
      class="relative flex max-h-screen flex-grow flex-col justify-items-stretch overflow-hidden"
      :scrollToTop="true"
    >
      <RouterView class="p-4" v-slot="{ Component }">
        <PageTransition>
          <Component :is="Component" />
        </PageTransition>
      </RouterView>
    </Scrollable>
  </div>
</template>
