<script lang="ts">
import { useLegalCompany } from '../../Loaders/legalCompany';
export { useLegalCompany };
</script>

<script setup lang="ts">
import { createEventBus, Spinner } from '@briefcase-app/briefcase-lib';
import LegalCompanyCard from '../../Components/molecules/cards/LegalCompanyCard.vue';
import { onMounted, onUnmounted } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { getLegalCompany } from '../../Loaders/legalCompany';

definePage({
  meta: {
    title: 'Kantoor',
  },
});

const route = useRoute('/kantoren/[legalCompanyId]');
const { data: legalCompany, isLoading } = useLegalCompany();

type PartialProperty = 'addresses' | 'phone_numbers' | 'email_addresses';

const updatePartial = async (property: PartialProperty) => {
  const data = await getLegalCompany(route.params.legalCompanyId);

  if (data) {
    (legalCompany.value[property] as (typeof data)[PartialProperty]) =
      data[property];
  }
};

const $legalCompanyPage = createEventBus('legalCompanyPage');

onMounted(() => {
  $legalCompanyPage.on('addressSaved', () => updatePartial('addresses'));
  $legalCompanyPage.on('phoneNumberSaved', () =>
    updatePartial('phone_numbers'),
  );
  $legalCompanyPage.on('emailAddressSaved', () => updatePartial('addresses'));
});

onUnmounted(() => {
  $legalCompanyPage.off('addressSaved', () => updatePartial('addresses'));
  $legalCompanyPage.off('phoneNumberSaved', () =>
    updatePartial('phone_numbers'),
  );
  $legalCompanyPage.off('emailAddressSaved', () => updatePartial('addresses'));
});
</script>

<template>
  <div
    class="page-content grid grid-cols-1 justify-between gap-4 lg:grid-cols-2"
  >
    <div class="flex w-full flex-col gap-4">
      <div>
        <div class="flex select-none flex-row items-center gap-2 text-center">
          <router-link to="/kantoren/">
            <p
              class="text-sm font-medium text-neutral-400 transition-all hover:text-neutral-900"
            >
              Kantoren
            </p>
          </router-link>
          <ChevronRightIcon class="h-4 text-neutral-400" />
          <p
            v-if="!isLoading || legalCompany"
            class="text-sm font-semibold text-neutral-900"
          >
            {{ legalCompany ? legalCompany.name : 'Nieuw kantoor' }}
          </p>
          <Spinner v-else class="h-3 text-neutral-400" />
        </div>
      </div>
      <Transition name="page-transition" mode="out-in" :duration="350" appear>
        <div
          class="page-content flex w-full flex-col gap-4"
          v-if="!isLoading || legalCompany"
        >
          <LegalCompanyCard v-model="legalCompany" :loading="isLoading" />
        </div>
      </Transition>
    </div>

    <div class="flex w-full flex-col">
      <div v-if="!isLoading || legalCompany">
        <RouterView v-slot="{ Component }">
          <Transition
            name="page-transition"
            mode="out-in"
            :duration="350"
            appear
          >
            <Component :is="Component" />
          </Transition>
        </RouterView>
      </div>
    </div>
  </div>
</template>
