<script setup lang="ts">
import { onMounted, type Ref, ref } from 'vue';
import { VButton, Card } from '@briefcase-app/briefcase-lib';
import { PencilSquareIcon } from '@heroicons/vue/20/solid';
import { DynamicTable } from '@briefcase-app/briefcase-lib';
import useBriefcase from '../../Helpers/useBriefcase';
import type {
  SuccessResponse,
  Client,
  QueryBuilderOptions,
} from '@briefcase-app/briefcase-api-sdk';
import router from '../../Helpers/router';
import { definePage } from 'unplugin-vue-router/runtime';

definePage({
  meta: {
    title: 'Klanten',
  },
});

const briefcase = useBriefcase();

const clientResponse: Ref<SuccessResponse<Client[]>> = ref({
  status: 'success',
  data: [],
  meta: {},
});

const loading = ref(false);

const queryBuilder: Ref<QueryBuilderOptions> = ref({
  filters: {},
  sorts: ['-created_at'],
  includes: ['address'],
  pagination: {
    page: 1,
    perPage: 10,
  },
});

const getClients = async () => {
  loading.value = true;

  const response = await briefcase.clients.index(queryBuilder.value);

  if (response.status === 'success') {
    clientResponse.value = response;
  }

  loading.value = false;
};

onMounted(() => {
  getClients();
});
</script>

<template>
  <div class="page-content">
    <div class="flex flex-row items-center justify-between">
      <div>
        <h1 class="font-semibold">Klanten</h1>
      </div>

      <div class="flex flex-row">
        <VButton @click="router.push('/klanten/nieuw')">
          <PencilSquareIcon class="h-4 w-4" />
          Nieuwe Klant
        </VButton>
      </div>
    </div>

    <div class="mt-5 w-full">
      <Card>
        <template #main>
          <DynamicTable
            :response="clientResponse"
            v-model:query="queryBuilder"
            :title="{ single: 'Klant', plural: 'Klanten' }"
            row-height="77px"
            :columns="[
              {
                name: 'Naam',
                key: 'name',
                sortable: true,
                width: 'w-5/12',
                component: () =>
                  import('../../Components/molecules/columns/ClientColumn.vue'),
                itemAsData: true,
              },
              {
                name: 'Telefoonnummer',
                key: 'client',
                sortable: false,
                width: 'w-3/12',
                component: () =>
                  import('../../Components/molecules/columns/PhoneColumn.vue'),
                itemAsData: true,
              },
              {
                name: 'Klant sinds',
                key: 'created_at',
                sortable: true,
                width: 'w-3/12',
                component: () =>
                  import(
                    '../../Components/molecules/columns/DateTimeColumn.vue'
                  ),
              },
              {
                name: '',
                key: 'client',
                sortable: false,
                width: 'w-1/12',
                component: () =>
                  import(
                    '../../Components/molecules/columns/ClientActionsColumn.vue'
                  ),
                itemAsData: true,
                noSkeleton: true,
              },
            ]"
            :loading="loading"
            @fetch="getClients"
          />
        </template>
      </Card>
    </div>
  </div>
</template>
