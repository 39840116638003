<script lang="ts">
import { useClient } from '../../Loaders/client';
export { useClient };
</script>

<script setup lang="ts">
import ClientOverview from '../../Components/molecules/ClientOverview.vue';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import useBriefcase from '../../Helpers/useBriefcase';
import { ref, watch } from 'vue';
import { type Client } from '@briefcase-app/briefcase-api-sdk';
import { RouterView, useRoute, useRouter } from 'vue-router';
import PageTransition from '../../Components/transitions/PageTransition.vue';
import { Spinner } from '@briefcase-app/briefcase-lib';
import ClientNotesCard from '../../Components/molecules/cards/ClientNotesCard.vue';

definePage({
  meta: {
    title: 'Klant',
  },
});

const briefcase = useBriefcase();
const route = useRoute('/klanten/[clientId]');
const router = useRouter();

const { data: client, isLoading: loading, reload: reloadClient } = useClient();
const clientName = ref(`${client.value.first_name} ${client.value.last_name}`);

const clientEditMode = ref(false);

const saveClient = async (data: Client) => {
  loading.value = true;

  const response = data.id
    ? await briefcase.clients.update(`client_${route.params.clientId}`, data)
    : await briefcase.clients.store(data);

  if (response.status === 'success') {
    clientEditMode.value = false;

    if (!data.id) {
      await router.replace({
        params: { clientId: response.data.id.replace('client_', '') },
      });
    }

    await reloadClient();
  }

  loading.value = false;
};

watch(client, (newClient) => {
  if (clientEditMode.value) return;

  if (newClient) {
    clientName.value = `${newClient.first_name} ${newClient.last_name}`;
  }
});
</script>

<template>
  <div
    class="page-content flex flex-col justify-between gap-8 xl:flex-row xl:gap-4"
  >
    <div class="flex w-full flex-col gap-4">
      <div>
        <div class="flex select-none flex-row items-center gap-2 text-center">
          <router-link to="/klanten">
            <p
              class="text-sm font-medium text-neutral-400 transition-all hover:text-neutral-900"
            >
              Klanten
            </p>
          </router-link>
          <ChevronRightIcon class="h-4 text-neutral-400" />
          <Spinner v-if="loading && !client" class="h-3 text-neutral-400" />
          <p v-else class="text-sm font-semibold text-neutral-900">
            {{ client?.hasOwnProperty('id') ? clientName : 'Nieuwe klant' }}
          </p>
        </div>
      </div>

      <PageTransition>
        <div class="page-content flex flex-col gap-4">
          <ClientOverview
            v-model="client"
            v-model:edit="clientEditMode"
            v-model:address="client.address"
            :loading="loading"
            @save="saveClient"
          />

          <ClientNotesCard
            :notes="route.params.clientId"
            v-if="route.params.clientId !== 'nieuw'"
          />
        </div>
      </PageTransition>
    </div>

    <div class="flex w-full flex-col">
      <div
        class="mb-4 flex select-none flex-row items-center gap-2 text-center"
      >
        <RouterLink
          :to="`/klanten/${route.params.clientId}`"
          v-if="
            router.currentRoute.value.name ===
            '/klanten/[clientId]/cases/[caseId]'
          "
        >
          <p
            class="flex items-center text-sm font-medium text-neutral-400 transition-all hover:text-neutral-900"
          >
            <ArrowLeftIcon class="mr-0.5 h-4" />
            Terug naar alle cases
          </p>
        </RouterLink>

        <p class="text-sm font-semibold text-neutral-900" v-else>Cases</p>
      </div>

      <div>
        <RouterView v-slot="{ Component }">
          <PageTransition>
            <Component :is="Component" />
          </PageTransition>
        </RouterView>
      </div>
    </div>
  </div>
</template>
