<template>
  <Transition name="page-transition" mode="out-in" :duration="350" appear>
    <slot></slot>
  </Transition>
</template>

<style lang="postcss">
body,
div,
aside,
main,
section {
  &.page-transition-enter-active,
  &.page-transition-leave-active,
  &:has(.page-transition-enter-active),
  &:has(.page-transition-leave-active) {
    overflow: hidden !important;
  }
}

.page-content {
  @apply flex-grow;
  will-change: transform, opacity;
}

.page-transition-enter-active,
.page-transition-leave-active {
  .page-content,
  &.page-content,
  .page-sidebar {
    transition: 350ms ease all;
  }

  .page-sidebar {
    transform: translateX(0);
  }
}

.page-transition-enter-from,
.page-transition-leave-to {
  .page-content,
  &.page-content {
    opacity: 0;
    transform: translateY(8px);
  }

  .page-sidebar {
    transform: translateX(-100%);
  }
}
</style>
