import 'vue-router';
import { createRouter, createWebHistory } from 'vue-router/auto';
import { handleHotUpdate } from 'vue-router/auto-routes';
import { routes } from 'vue-router/auto-routes';
import { useAuthStore } from '../Stores/AuthStore';
import pageTitle from './pageTitle';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.authToken) {
    return '/login';
  } else {
    if (auth.authToken && to.path === '/login') return '/';
    return true;
  }
});

router.beforeEach((to, from) => {
  if (!to.meta?.useParentTitle && from)
    document.title = pageTitle(to.meta?.title?.toString() ?? null);
});

if (import.meta.hot) {
  handleHotUpdate(router);
}

export default router;
