import { RestModule } from '../RestModule.class';
import {
  NewPaymentProfile,
  PaymentProfile,
} from '../../Models/legalCompany/paymentProfile.model';

export class PaymentProfiles extends RestModule<
  PaymentProfile,
  NewPaymentProfile
> {
  constructor() {
    super('payment-profiles');
  }
}
