<script setup lang="ts">
import {
  PerfectScrollbar,
  type PerfectScrollbarExpose,
} from 'vue3-perfect-scrollbar';
import { nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const props = withDefaults(
  defineProps<{
    is?: string;
    scrollToTop?: boolean;
  }>(),
  {
    is: 'div',
    scrollToTop: false,
  },
);

const contentHeight = ref('auto');

const updateHeight = () => {
  const viewportHeight = window.innerHeight;
  contentHeight.value = `${Math.ceil(viewportHeight) + 1}px`;
};

watch(() => window.innerHeight, updateHeight);

onMounted(() => {
  updateHeight();
  window.addEventListener('resize', updateHeight);
});

const route = useRoute();
const scrollable = ref<PerfectScrollbarExpose | null>(null);

watch(
  () => route.fullPath,
  (newValue, oldValue) => {
    if (!props.scrollToTop || !scrollable.value) return;
    if (newValue !== oldValue) {
      if (!route.meta.abortScrollToTop) {
        nextTick(() => {
          scrollable.value?.ps?.element.scrollTo(0, 0);
        });
      }
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <Component :is="is">
    <PerfectScrollbar
      ref="scrollable"
      :style="{
        height: contentHeight,
        minHeight: contentHeight,
        maxHeight: contentHeight,
      }"
    >
      <slot />
    </PerfectScrollbar>
  </Component>
</template>

<style lang="postcss">
.ps {
  &:hover,
  &:focus,
  &:active {
    .ps__rail-y,
    .ps__rail-x {
      background: transparent !important;
    }
  }
}

.ps__rail-y:hover,
.ps--clicking {
  .ps__rail-y,
  .ps__thumb-y {
    width: 8px !important;
  }
}

.ps__rail-x,
.ps__rail-y {
  background: transparent !important;
}

.ps__thumb-y {
  right: 4px !important;
}

.ps__rail-y,
.ps__thumb-y {
  width: 6px !important;
}
</style>
