import './styles.scss';

import App from './app/App.vue';
import { createApp } from 'vue';
import router from './app/Helpers/router';
import { BriefcaseAPISDK } from '@briefcase-app/briefcase-api-sdk';
import { useAuthStore } from './app/Stores/AuthStore';
import pinia from './app/Plugins/pinia';
import useCallEntryStore from './app/Stores/CallEntryStore';
import flagsmith from 'flagsmith';
import useGleap from './app/Helpers/useGleap';
import 'vue3-perfect-scrollbar/style.css';
import * as Sentry from '@sentry/vue';
import useSharedStore from './app/Stores/SharedStore';
import { DataLoaderPlugin } from 'unplugin-vue-router/data-loaders';

const briefcase = new BriefcaseAPISDK(import.meta.env.VITE_BRIEFCASE_API_URL);

const app = createApp(App);
app.provide('briefcase', briefcase);

app.use(pinia);

app.use(DataLoaderPlugin, { router });
app.use(router);

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment:
      import.meta.env.MODE === 'development' ? 'local' : import.meta.env.MODE,
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.juridisch\.nl/,
      /^https:\/\/briefcase-api\.test/,
    ],
    replaysSessionSampleRate:
      import.meta.env.VITE_SENTRY_SESSION_SAMPLE_RATE ?? 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.mount('#root');

const authStore = useAuthStore();
const authToken = authStore.authToken;
const impersonationToken = authStore.impersonationToken;

if (authToken || impersonationToken) {
  briefcase.auth.setAuthToken(impersonationToken ?? authToken);
  authStore.getUser();
}

flagsmith.init({
  api: 'https://flags.skrypt.nl/api/v1/',
  environmentID: import.meta.env.VITE_FLAGS_ENVIRONMENT,
  onChange: () => {
    authStore.flags = flagsmith.getAllFlags();
  },
  identity: authStore.user?.id,
});

const callEntryStore = useCallEntryStore();
callEntryStore.subscribe();

const sharedStore = useSharedStore();
sharedStore.init();

if (authStore.hasFeature('gleap')) useGleap();
