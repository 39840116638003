import { RestModule } from '../RestModule.class';
import { Client, NewClient } from '../../Models/client.model';
import {
  ApiResponse,
  QueryBuilderOptions,
} from '@briefcase-app/briefcase-api-sdk';
import { ClientNote } from '../../Models/clientNote.model';

export class Clients extends RestModule<Client, NewClient> {
  constructor() {
    super('clients');
  }

  notes(
    clientId: string,
    options: QueryBuilderOptions = {},
  ): Promise<ApiResponse<ClientNote[]>> {
    if (!clientId.startsWith('client_')) clientId = `client_${clientId}`;

    const endpoint = `${this.endpoint}/${clientId}/notes`;

    return this.request(
      'get',
      `${endpoint}/${this.formatQueryBuilderParams(options)}`,
    );
  }

  saveNote(
    clientId: string,
    content: string,
  ): Promise<ApiResponse<ClientNote>> {
    if (!clientId.startsWith('client_')) clientId = `client_${clientId}`;
    return this.request('post', `${this.endpoint}/${clientId}/notes`, {
      client_id: clientId,
      content,
    });
  }
}
