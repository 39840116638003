import { defineStore } from 'pinia';
import type { CallEntry } from '@briefcase-app/briefcase-api-sdk';
import { usePusher } from '../Helpers/usePusher';

const { subscribeToChannel } = usePusher();

export const useCallEntryStore = defineStore({
  id: 'callEntries',
  state: () => ({
    activeCalls: [] as CallEntry[],
  }),
  actions: {
    subscribe() {
      subscribeToChannel('calls.created', 'CallCreated', this.addCall);
    },
    addCall({ callEntry }) {
      subscribeToChannel(`calls.${callEntry.id}`, 'CallUpdated', () => {
        this.removeCall(callEntry);
      });
      this.activeCalls.push(callEntry);
    },
    removeCall(call: CallEntry) {
      this.activeCalls = this.activeCalls.filter(
        (c: CallEntry) => c.id !== call.id,
      );
    },
  },
  persist: true,
});

export default useCallEntryStore;
