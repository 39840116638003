<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, ref } from 'vue';
import useBriefcase from '../../../../Helpers/useBriefcase';
import type { CaseRecord } from '@briefcase-app/briefcase-api-sdk';
import CaseCard from '../../../../Components/molecules/cards/CaseCard.vue';

definePage({
  meta: {
    title: 'Cases',
  },
});

const briefcase = useBriefcase();
const loading = ref(false);

const route = useRoute('/klanten/[clientId]/cases/[caseId]');
const router = useRouter();
const params = route.params;

const caseId = computed(() => params.caseId.toString().replace('case_', ''));
const isNewCase = computed(() => caseId.value === 'nieuw');

const caseRecord = ref<CaseRecord>(null);

const getCaseRecord = async () => {
  loading.value = true;

  if (isNewCase.value) {
    caseRecord.value = {
      province_id: 1,
      client_id: `client_${route.params.clientId}`,
      status: 'open',
      details: '',
      addition_income: null,
      addition_shift_income: null,
      addition_assets: null,
      addition_result: null,
      is_addition: false,
    } as CaseRecord;

    loading.value = false;

    return;
  }

  const response = await briefcase.cases.show(`case_${caseId.value}`);

  if (response.status === 'success') {
    caseRecord.value = response.data;
  }

  loading.value = false;
};

const saved = ref(false);
const savedTimeout = ref(null);

const saveCaseRecord = async () => {
  if (!caseRecord.value) return;

  loading.value = true;

  const response = isNewCase.value
    ? await briefcase.cases.store(caseRecord.value)
    : await briefcase.cases.update(caseRecord.value.id, caseRecord.value);

  if (response.status === 'success') {
    caseRecord.value = response.data;

    if (isNewCase.value) {
      const caseId = response.data.id.replace('case_', '');

      await router.push(`/klanten/${route.params.clientId}/cases/${caseId}`);
    }
  }

  saved.value = true;

  if (savedTimeout.value) {
    clearTimeout(savedTimeout.value);
  }

  savedTimeout.value = setTimeout(() => {
    saved.value = false;
  }, 4000);

  loading.value = false;
};

onBeforeMount(async () => {
  await getCaseRecord();
});
</script>

<template>
  <div class="page-content w-full" v-if="caseRecord">
    <CaseCard v-model="caseRecord" @save="saveCaseRecord" :loading :saved />
  </div>
</template>
