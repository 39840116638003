import _definePage_default_0 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/index.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId]/advocaten/[legalEmployeeId].vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId].vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/index.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/[clientId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/[clientId]/cases/[caseId].vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/[clientId].vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/login.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/instellingen',
    name: '/instellingen',
    component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen.vue'),
    children: [
      {
        path: 'formulieren',
        name: '/instellingen/formulieren',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/formulieren.vue'),
        /* no children */
      },
      {
        path: 'formulieren/:id',
        name: '/instellingen/formulieren.[id]',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/formulieren.[id].vue'),
        /* no children */
      },
      {
        path: 'gebruikers',
        name: '/instellingen/gebruikers',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/gebruikers.vue'),
        /* no children */
      },
      {
        path: 'inkomensnormen',
        name: '/instellingen/inkomensnormen',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/inkomensnormen.vue'),
        /* no children */
      },
      {
        path: 'rechtsgebieden',
        name: '/instellingen/rechtsgebieden',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/rechtsgebieden.vue'),
        /* no children */
      },
      {
        path: 'verenigingen',
        name: '/instellingen/verenigingen',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/verenigingen.vue'),
        /* no children */
      },
      {
        path: 'zaaktypes',
        name: '/instellingen/zaaktypes',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/instellingen/zaaktypes.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_2
  ),
  {
    path: '/kantoren',
    /* internal name: '/kantoren' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/kantoren/',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: ':legalCompanyId',
        name: '/kantoren/[legalCompanyId]',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId].vue'),
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/kantoren/[legalCompanyId]/',
            component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId]/index.vue'),
            /* no children */
          },
  _definePage_default_4
  ),
          {
            path: 'advocaten',
            /* internal name: '/kantoren/[legalCompanyId]/advocaten' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':legalEmployeeId',
                name: '/kantoren/[legalCompanyId]/advocaten/[legalEmployeeId]',
                component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId]/advocaten/[legalEmployeeId].vue'),
                /* no children */
              },
  _definePage_default_5
  )
            ],
          },
          {
            path: 'contracten',
            /* internal name: '/kantoren/[legalCompanyId]/contracten' */
            /* no component */
            children: [
              {
                path: ':contractId',
                name: '/kantoren/[legalCompanyId]/contracten/[contractId]',
                component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/kantoren/[legalCompanyId]/contracten/[contractId].vue'),
                /* no children */
              }
            ],
          }
        ],
      },
  _definePage_default_6
  )
    ],
  },
  {
    path: '/klanten',
    /* internal name: '/klanten' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/klanten/',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/index.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
  _mergeRouteRecord(
      {
        path: ':clientId',
        name: '/klanten/[clientId]',
        component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/[clientId].vue'),
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/klanten/[clientId]/',
            component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/[clientId]/index.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
          {
            path: 'cases',
            /* internal name: '/klanten/[clientId]/cases' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':caseId',
                name: '/klanten/[clientId]/cases/[caseId]',
                component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/klanten/[clientId]/cases/[caseId].vue'),
                /* no children */
              },
  _definePage_default_9
  )
            ],
          }
        ],
      },
  _definePage_default_10
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/briefcase-app/webapps/briefcase-app/releases/1731455695317/apps/briefcase-app/src/app/Pages/login.vue'),
    /* no children */
  },
  _definePage_default_11
  )
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

