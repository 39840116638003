<script lang="ts">
import { useLegalCompany } from '../../../Loaders/legalCompany';
export { useLegalCompany };
</script>

<script setup lang="ts">
import Employees from '../../../Components/organisms/legalCompany/Employees.vue';
import Contracts from '../../../Components/organisms/legalCompany/Contracts.vue';
import hasRole from '../../../Helpers/hasRole';

definePage({
  meta: {
    title: 'Kantoor',
  },
});

const { data: legalCompany } = useLegalCompany();
</script>

<template>
  <div class="page-content flex flex-col gap-8" v-if="legalCompany.id">
    <Employees :legalCompanyId="legalCompany.id" />

    <Contracts :legalCompanyId="legalCompany.id" v-if="hasRole('admin')" />
  </div>
</template>
