<script lang="ts">
import { useLegalCompanies } from '../../Loaders/legalCompanies';
export { useLegalCompanies };
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { VButton, Card, VLabel } from '@briefcase-app/briefcase-lib';
import { DynamicTable } from '@briefcase-app/briefcase-lib';
import NewLegalCompanyModal from '../../Components/molecules/modals/NewLegalCompanyModal.vue';
import router from '../../Helpers/router';
import { PlusIcon } from '@heroicons/vue/16/solid';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import type {
  LegalCompany,
  SuccessResponse,
} from '@briefcase-app/briefcase-api-sdk';

definePage({
  meta: {
    title: 'Kantoren',
  },
});

const { data, isLoading, reload } = useLegalCompanies();

const response = computed(() => data.value.response);
const queryBuilder = computed(() => data.value.queryBuilder.value);

const showCreateModal = ref(false);
</script>

<template>
  <div class="page-content">
    <div class="flex flex-row items-center justify-between">
      <div>
        <h1 class="font-semibold">Kantoren</h1>
      </div>

      <div class="flex flex-row">
        <VButton @click="showCreateModal = true" :leftIcon="PlusIcon">
          Kantoor toevoegen
        </VButton>

        <NewLegalCompanyModal
          :is-open="showCreateModal"
          @close="showCreateModal = false"
        />
      </div>
    </div>

    <div class="mt-5 w-full">
      <Card>
        <template #main>
          <DynamicTable
            :response="response as SuccessResponse<LegalCompany[]>"
            use-route-params
            v-model:query="queryBuilder"
            :title="{ single: 'Kantoor', plural: 'Kantoren' }"
            row-height="53px"
            :columns="[
              {
                name: 'Naam',
                key: 'name',
                css: 'font-medium text-gray-900',
                sortable: true,
                width: 'w-7/12',
              },
              {
                name: 'Aantal advocaten',
                key: 'legal_employees_count',
                slotName: 'EmployeesCount',
                sortable: false,
                width: 'w-1/6',
              },
              {
                name: 'Aangemaakt op',
                key: 'created_at',
                sortable: true,
                width: 'w-1/6',
                modifier: (value: any) => {
                  return new Intl.DateTimeFormat('nl-NL', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }).format(new Date(value));
                },
              },
              {
                name: '',
                key: '',
                css: 'flex justify-end items-center',
                slotName: 'actions',
                width: 'w-1/12',
              },
            ]"
            :loading="isLoading"
            @fetch="reload"
          >
            <template #EmployeesCount="{ data }">
              <div class="flex">
                <VLabel :style="data > 0 ? 'info' : 'neutral'">
                  {{ data }}
                </VLabel>
              </div>
            </template>
            <template #actions="{ item }">
              <VButton
                @click="router.push(`/kantoren/${item?.slug || item?.id}`)"
                :style="'icon'"
              >
                <ChevronRightIcon class="h-6 w-6" />
              </VButton>
            </template>
          </DynamicTable>
        </template>
      </Card>
    </div>
  </div>
</template>
