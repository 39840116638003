<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import CallAlert from './Components/molecules/CallAlert.vue';
import {
  InboxIcon,
  LifebuoyIcon,
  FingerPrintIcon,
} from '@heroicons/vue/20/solid';
import {
  BriefcaseIcon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
} from '@heroicons/vue/24/solid';
import {
  Sidebar,
  SideBarButton,
  createEventBus,
  Scrollable,
} from '@briefcase-app/briefcase-lib';
import UserMenu from './Components/atoms/UserMenu.vue';
import { computed, ref, watch } from 'vue';
import { getGravatarUrl } from '@briefcase-app/briefcase-api-sdk';
import useAuthStore from './Stores/AuthStore';
import Gleap from 'gleap';
import PageTransition from './Components/transitions/PageTransition.vue';
import * as Sentry from '@sentry/vue';

const route = useRoute();
const hideSidebar = computed(() => route.meta.hideSidebar === true);

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isImpersonating = authStore.impersonationToken !== null;

const gleapIsOpen = ref(false);

watch(user, (u) => {
  if (u) {
    Sentry.setUser({
      id: u.id,
      email: u.email,
    });

    if (authStore.hasFeature('gleap')) {
      Gleap.identify(
        u.id,
        {
          email: u.email,
          name: u.name,
        },
        u.id_hash,
      );
    }
  } else {
    Sentry.setUser(null);

    if (authStore.hasFeature('gleap')) {
      Gleap.clearIdentity();
    }
  }
});

if (authStore.hasFeature('gleap')) {
  Gleap.on('open', () => {
    gleapIsOpen.value = true;
  });

  Gleap.on('close', () => {
    gleapIsOpen.value = false;
  });
}

createEventBus();
</script>

<template>
  <div class="flex max-h-screen min-h-screen flex-col overflow-hidden">
    <div
      v-if="isImpersonating"
      class="font-regular flex items-center justify-center gap-8 bg-blue-600 p-2 text-center text-sm text-white"
    >
      <span class="flex items-center gap-1.5">
        <FingerPrintIcon class="h-3.5 w-3.5" />
        Je doet je voor als {{ user?.name }}
      </span>
      <button
        @click="authStore.stopImpersonation()"
        class="text-white underline transition-all duration-75 ease-in-out hover:text-blue-100"
      >
        Terug naar je eigen account
      </button>
    </div>
    <div class="flex flex-grow">
      <Sidebar v-if="!hideSidebar" class="z-10">
        <template v-slot:header>
          <div class="mt-4 flex flex-col gap-4">
            <div
              class="flex flex-row content-center items-center justify-between gap-4"
            >
              <div class="flex flex-grow flex-row items-center gap-3">
                <div class="flex-shrink-0">
                  <img
                    :src="getGravatarUrl(user?.email)"
                    alt="Profile picture"
                    class="h-10 w-10 rounded-lg border border-neutral-700 object-cover"
                  />
                </div>
                <div
                  class="flex flex-grow flex-col gap-0.5 truncate text-sm tracking-wide text-white"
                >
                  <p class="truncate font-medium">
                    {{ user?.name ?? 'Gebruiker' }}
                  </p>
                  <p
                    v-if="user?.email"
                    class="truncate text-xs font-light text-neutral-300"
                  >
                    {{ user.email }}
                  </p>
                </div>
              </div>
              <UserMenu />
            </div>
          </div>
        </template>

        <template v-slot:main>
          <div class="flex flex-col gap-2">
            <SideBarButton to="/" name="Takenlijst" check-strict-path>
              <template #icon>
                <InboxIcon class="h-4 w-4" />
              </template>
            </SideBarButton>

            <SideBarButton to="/klanten" name="Klanten">
              <template #icon>
                <BriefcaseIcon class="h-4 w-4" />
              </template>
            </SideBarButton>
          </div>

          <div class="flex flex-col gap-3">
            <h2 class="text-xs font-semibold uppercase text-white">Relaties</h2>

            <SideBarButton to="/kantoren" name="Kantoren">
              <template #icon>
                <BuildingOffice2Icon class="h-4 w-4" />
              </template>
            </SideBarButton>
          </div>

          <div class="flex flex-col gap-3">
            <h2 class="text-xs font-semibold uppercase text-white">Admin</h2>

            <SideBarButton to="/instellingen" name="Instellingen">
              <template #icon>
                <Cog6ToothIcon class="h-4 w-4" />
              </template>
            </SideBarButton>
          </div>
        </template>

        <template v-slot:footer v-if="authStore.hasFeature('gleap')">
          <div class="mt-auto">
            <button
              v-if="authStore.hasFeature('gleap')"
              @click="gleapIsOpen ? Gleap.close() : Gleap.open()"
              class="flex w-full cursor-pointer flex-row items-center gap-4 rounded-md px-2 py-2 transition-all hover:bg-white hover:text-black"
              :class="gleapIsOpen ? 'bg-white text-black' : 'text-white'"
            >
              <LifebuoyIcon class="h-4 w-4" />
              Ondersteuning
            </button>
          </div>
        </template>
      </Sidebar>

      <Scrollable
        is="main"
        class="relative flex flex-grow flex-col justify-items-stretch overflow-hidden"
        :scrollToTop="true"
      >
        <RouterView class="p-4" v-slot="{ Component }">
          <PageTransition>
            <Component :is="Component" />
          </PageTransition>
        </RouterView>
      </Scrollable>
    </div>

    <CallAlert v-if="authStore.hasFeature('call_alert')" />
  </div>
</template>

<style lang="postcss">
body {
  @apply bg-neutral-100 text-neutral-900;
}

hr {
  @apply border-neutral-200;
}
</style>
