import { Users } from './Modules/Users/users';
import { Auth } from './Modules/Auth/auth';
import AxiosClient from './Helpers/AxiosClient';
import { CaseRecords } from './Modules/CaseRecords/caseRecords';
import { Clients } from './Modules/Client/clients';
import { LegalCompanies } from './Modules/LegalCompany/legalCompanies';
import { LegalAreas } from './Modules/LegalArea/legalAreas';
import { CaseTypes } from './Modules/CaseType/caseTypes';
import { Forms } from './Modules/Form/forms';
import { FormQuestions } from './Modules/Form/formQuestions';
import { PostalCodes } from './Modules/Postcode/postalCodes';
import { IncomeStandards } from './Modules/IncomeStandards/incomeStandards';
import { Addresses } from './Modules/Address.class';
import { Images } from './Modules/Images/images';
import { PhoneNumbers } from './Modules/PhoneNumbers.class';
import { LegalEmployees } from './Modules/LegalCompany/legalEmployees';
import { EmailAddresses } from './Modules/EmailAddresses';
import { LegalAssociations } from './Modules/LegalAssociation/legalAssociations';
import { Contracts } from './Modules/LegalCompany/contracts';
import { PaymentProfiles } from './Modules/LegalCompany/paymentProfiles';
import { Provinces } from './Modules/provinces';

export class BriefcaseAPISDK {
  public users: Users;
  public auth: Auth;
  public cases: CaseRecords;
  public clients: Clients;
  public legalCompanies: LegalCompanies;
  public legalEmployees: LegalEmployees;
  public legalAreas: LegalAreas;
  public legalAssociations: LegalAssociations;
  public caseTypes: CaseTypes;
  public forms: Forms;
  public formQuestions: FormQuestions;
  public postcode: PostalCodes;
  public incomeStandards: IncomeStandards;
  public addresses: Addresses;
  public phoneNumbers: PhoneNumbers;
  public emailAddresses: EmailAddresses;
  public images: Images;
  public contracts: Contracts;
  public paymentProfiles: PaymentProfiles;
  public provinces: Provinces;

  constructor(baseURL = 'https://api.juridisch.nl/v1') {
    if (baseURL.endsWith('/')) baseURL = baseURL.slice(0, -1);

    AxiosClient.initialize({ baseURL });

    this.users = new Users();
    this.auth = new Auth();
    this.cases = new CaseRecords();
    this.clients = new Clients();
    this.legalCompanies = new LegalCompanies();
    this.legalEmployees = new LegalEmployees();
    this.legalAreas = new LegalAreas();
    this.legalAssociations = new LegalAssociations();
    this.caseTypes = new CaseTypes();
    this.forms = new Forms();
    this.formQuestions = new FormQuestions();
    this.postcode = new PostalCodes();
    this.incomeStandards = new IncomeStandards();
    this.addresses = new Addresses();
    this.phoneNumbers = new PhoneNumbers();
    this.emailAddresses = new EmailAddresses();
    this.images = new Images();
    this.contracts = new Contracts();
    this.paymentProfiles = new PaymentProfiles();
    this.provinces = new Provinces();
  }
}
