import { Module } from './Module.class';
import { ApiResponse, ErrorResponse } from '../Models/apiResponse.model';
import { AxiosError } from 'axios';
import { QueryBuilderOptions } from '../Models/queryBuilderOptions.model';

export abstract class RestModule<Model, NewModel> extends Module {
  protected endpoint: string;

  protected constructor(endpoint: string) {
    super();
    this.endpoint = endpoint;
  }

  protected async request<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    endpoint: string,
    data?: unknown,
  ): Promise<ApiResponse<T>> {
    let response: ApiResponse<T>;
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    switch (method) {
      case 'get':
        response = await this.client
          .get<ApiResponse<T>>(endpoint, { headers })
          .then(({ data }) => data)
          .catch((err) => this.handleError(err));
        break;
      case 'post':
        response = await this.client
          .post<ApiResponse<T>>(endpoint, data)
          .then(({ data }) => data)
          .catch((err) => this.handleError(err));
        break;
      case 'put':
        response = await this.client
          .put<ApiResponse<T>>(endpoint, data)
          .then(({ data }) => data)
          .catch((err) => this.handleError(err));
        break;
      case 'delete':
        response = await this.client
          .delete<ApiResponse<T>>(endpoint)
          .then(({ data }) => data)
          .catch((err) => this.handleError(err));
        break;
    }
    return response;
  }

  private handleError(error: unknown): ErrorResponse {
    if (error instanceof AxiosError) {
      return {
        status: error.response?.status || 500,
        message: error.message || 'Unknown server error',
        data: null,
        meta: null,
      };
    } else {
      return {
        status: 500,
        message: 'Could not perform API request',
        data: null,
        meta: null,
      };
    }
  }

  protected formatQueryBuilderParams(options: QueryBuilderOptions): string {
    const params = new URLSearchParams();

    if (options.filters) {
      for (const [key, value] of Object.entries(options.filters)) {
        params.append(`filter[${key}]`, value as string);
      }
    }

    if (options.sorts) {
      params.append('sort', options.sorts.join(','));
    }

    if (options.includes) {
      params.append('include', options.includes.join(','));
    }

    if (options.fields) {
      if (Array.isArray(options.fields)) {
        params.append('fields', options.fields.join(','));
      } else {
        for (const [model, fields] of Object.entries(options.fields)) {
          params.append(`fields[${model}]`, fields.join(','));
        }
      }
    }

    if (options.pagination) {
      if (
        options.pagination.page !== undefined &&
        options.pagination.page !== null
      )
        params.append('page', options.pagination.page.toString());

      if (
        options.pagination.perPage !== undefined &&
        options.pagination.perPage !== null
      )
        params.append('perPage', options.pagination.perPage.toString());
    }

    return `?${params.toString()}`;
  }

  public async index(
    options?: QueryBuilderOptions,
  ): Promise<ApiResponse<Model[]>> {
    let endpoint = `/${this.endpoint}/`;

    if (options) {
      endpoint += this.formatQueryBuilderParams(options);
    }

    return this.request<Model[]>('get', endpoint);
  }

  public async store(data: NewModel): Promise<ApiResponse<Model>> {
    return this.request<Model>('post', `/${this.endpoint}/`, data);
  }

  public async show(
    id: string | number,
    options?: QueryBuilderOptions,
  ): Promise<ApiResponse<Model>> {
    let endpoint = `/${this.endpoint}/${id}/`;

    if (options) {
      endpoint += this.formatQueryBuilderParams(options);
    }

    return this.request<Model>('get', endpoint);
  }

  public async update(
    id: string | number,
    data: Partial<Model>,
  ): Promise<ApiResponse<Model>> {
    return this.request<Model>('put', `/${this.endpoint}/${id}`, data);
  }

  public async destroy(id: string | number): Promise<ApiResponse<null>> {
    return this.request<null>('delete', `/${this.endpoint}/${id}`);
  }
}
