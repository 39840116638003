import {
  LegalCompany,
  NewLegalCompany,
} from '../../Models/legalCompany/legalCompany.model';
import { ContractsCount } from '../../Models/legalCompany/contractsCount.model';
import { RestModule } from '../RestModule.class';
import { ApiResponse } from '../../Models/apiResponse.model';
import { PaymentProfile } from '@briefcase-app/briefcase-api-sdk';

export class LegalCompanies extends RestModule<LegalCompany, NewLegalCompany> {
  constructor() {
    super('legal-companies');
  }

  getContractsCount(
    legalCompanyId: string,
  ): Promise<ApiResponse<ContractsCount>> {
    return this.request(
      'get',
      `/${this.endpoint}/${legalCompanyId}/contracts/count`,
    );
  }

  paymentProfiles(
    legalCompanyId: string,
  ): Promise<ApiResponse<PaymentProfile[]>> {
    return this.request(
      'get',
      `/${this.endpoint}/${legalCompanyId}/payment-profiles`,
    );
  }
}
