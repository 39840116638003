import { CaseType, NewCaseType } from '../../Models/case/caseType.model';
import { RestModule } from '../RestModule.class';
import { ApiResponse } from '../../Models/apiResponse.model';

interface response {
  suggestions: CaseType[];
  relevance: Record<string, number>;
}

export class CaseTypes extends RestModule<CaseType, NewCaseType> {
  constructor() {
    super('case-types');
  }

  public async getSuggestions(query: string): Promise<ApiResponse<response>> {
    const endpoint = '/openai/suggest/case-types';

    return this.request<response>('post', endpoint, { query });
  }
}
