<script lang="ts">
import { useCases } from '../../../Loaders/cases';
export { useCases };
</script>

<script setup lang="ts">
import {
  getCaseStatusLabel,
  getCaseStatusLabelStyle,
} from '@briefcase-app/briefcase-api-sdk';
import { useRoute, useRouter } from 'vue-router';
import { Card, ItemCard, VLabel, VButton } from '@briefcase-app/briefcase-lib';
import { BriefcaseIcon } from '@heroicons/vue/24/outline';
import { ChevronRightIcon, PlusIcon } from '@heroicons/vue/20/solid';
import { ArrowLeftIcon } from '@heroicons/vue/16/solid';
import { computed } from 'vue';

definePage({
  meta: {
    title: 'Klant',
  },
});

const { data: cases } = useCases();

const router = useRouter();
const route = useRoute('/klanten/[clientId]/');

const clientId = computed(() =>
  route.params.clientId === 'nieuw'
    ? 'nieuw'
    : `client_${route.params.clientId}`,
);
const isNewClient = computed(() => clientId.value === 'nieuw');
</script>

<template>
  <div class="page-content">
    <Card>
      <template v-if="isNewClient" #overlay>
        <div
          class="absolute left-0 top-0 z-20 flex h-full w-full select-none items-center justify-center overflow-hidden rounded-[inherit] border-[inherit] bg-neutral-50/25 p-4 text-sm font-medium tracking-wider text-neutral-400 backdrop-blur-sm"
        >
          <VLabel :style="'neutral'">
            <ArrowLeftIcon class="mr-2 h-3 w-3 flex-shrink-0" />
            Sla eerst de nieuwe klant op
          </VLabel>
        </div>
      </template>

      <template #header>
        <div class="card-header">
          <div class="card-header__left">
            <BriefcaseIcon class="card-header__icon" />

            <h1 class="card-header__title">Cases</h1>
          </div>
        </div>
      </template>

      <template #main>
        <div class="flex flex-col gap-3" v-if="cases && cases.length > 0">
          <ItemCard
            v-for="caseRecord in cases"
            @click="
              router.push(
                `/klanten/${clientId.replace('client_', '')}/cases/${caseRecord.id.toString().replace('case_', '')}`,
              )
            "
            v-bind:key="caseRecord.id"
            class="cursor-pointer text-gray-400 hover:text-black"
            :use-click-handler="false"
          >
            <div class="flex flex-col">
              <h1 class="text-base font-semibold text-neutral-900">
                {{ caseRecord.id }}
              </h1>
            </div>

            <div
              class="ml-auto flex cursor-pointer flex-row gap-2 transition-all"
            >
              <VLabel :style="getCaseStatusLabelStyle(caseRecord.status)">
                {{ getCaseStatusLabel(caseRecord.status) }}
              </VLabel>
              <ChevronRightIcon class="h-6 w-6" />
            </div>
          </ItemCard>
        </div>
        <div v-else class="text-sm text-gray-400">
          Deze klant heeft nog geen zaken
        </div>
      </template>

      <template #footer>
        <div>
          <VButton
            @click="
              router.push(`/klanten/${route.params.clientId}/cases/nieuw`)
            "
            :disabled="isNewClient"
          >
            <PlusIcon class="h-4 w-4" />
            Nieuwe case
          </VButton>
        </div>
      </template>
    </Card>
  </div>
</template>
