// @TODO Replace with data loader

import { defineStore } from 'pinia';
import {
  BriefcaseAPISDK,
  type CaseType,
  type LegalArea,
} from '@briefcase-app/briefcase-api-sdk';

interface SharedStoreState {
  caseTypes: CaseType[];
  legalAreas: LegalArea[];
}

export const useSharedStore = defineStore({
  id: 'sharedStore',

  state: () =>
    ({
      caseTypes: [],
      legalAreas: [],
    }) as SharedStoreState,

  getters: {
    parentLegalAreas: (state: SharedStoreState) =>
      state.legalAreas.filter((area) => !area.parent_id),
    parentLegalAreasWithChildren: (state: SharedStoreState) =>
      state.legalAreas.filter((area) => {
        const usedLegalAreas = [
          ...new Set(
            state.legalAreas
              .filter((area) => area.parent_id !== null)
              .map((area) => area.parent_id),
          ),
        ];

        return !area.parent_id && usedLegalAreas.includes(area.id);
      }),
    childLegalAreas: (state: SharedStoreState) =>
      state.legalAreas.filter((area) => area.parent_id !== null),
  },

  actions: {
    async init() {
      await Promise.all([this.fetchLegalAreas(), this.fetchCaseTypes()]);
    },
    async fetchCaseTypes() {
      const briefcase = new BriefcaseAPISDK();
      const response = await briefcase.caseTypes.index();

      if (response.status === 'success') {
        this.caseTypes = response.data;
      }
    },
    async fetchLegalAreas() {
      const briefcase = new BriefcaseAPISDK();
      const response = await briefcase.legalAreas.index();

      if (response.status === 'success') {
        this.legalAreas = response.data;
      }
    },
    getLegalArea(legalAreaId: string | number): LegalArea | null {
      return this.legalAreas.find(
        (area: LegalArea) => area.id.toString() === legalAreaId.toString(),
      );
    },
    getParentLegalArea(
      childLegalAreaId: string | number | LegalArea,
    ): LegalArea | null {
      if (typeof childLegalAreaId === 'object') {
        childLegalAreaId = childLegalAreaId.id;
      }

      return this.legalAreas.find(
        (area: LegalArea) => area.id.toString() === childLegalAreaId.toString(),
      );
    },
  },

  persist: false,
});

export default useSharedStore;
