import Pusher from 'pusher-js';
import * as Sentry from '@sentry/vue';

const channels = [];

export function usePusher() {
  const pusher = new Pusher(import.meta.env.VITE_REVERB_APP_KEY, {
    cluster: 'mt1',
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    enabledTransports: ['ws', 'wss'],
  });

  const subscribeToChannel = (
    channelName: string,
    eventName: string,
    callback: (data: unknown) => void,
  ) => {
    const channel = pusher.subscribe(channelName);

    channel.bind(eventName, callback);

    channel.bind('pusher:subscription_error', (error: Error) => {
      Sentry.captureException(error);
    });

    channels.push(channel);

    return channel;
  };

  return { subscribeToChannel };
}
