import { RestModule } from '../RestModule.class';
import {
  Contract,
  NewContract,
} from '../../Models/legalCompany/contract.model';

export class Contracts extends RestModule<Contract, NewContract> {
  constructor() {
    super('contracts');
  }
}
