<script setup lang="ts">
import { onMounted, type Ref, ref } from 'vue';
import { VButton, Card } from '@briefcase-app/briefcase-lib';
import { ChevronRightIcon, PencilSquareIcon } from '@heroicons/vue/20/solid';
import { DynamicTable } from '@briefcase-app/briefcase-lib';
import useBriefcase from '../Helpers/useBriefcase';
import type {
  SuccessResponse,
  QueryBuilderOptions,
  CaseRecord,
  Fieldset,
  Client,
} from '@briefcase-app/briefcase-api-sdk';
import CaseColumn from '../Components/molecules/columns/CaseColumn.vue';
import { useRouter } from 'vue-router';
import { definePage } from 'unplugin-vue-router/runtime';

definePage({
  meta: {
    title: 'Takenlijst',
  },
});

const briefcase = useBriefcase();
const router = useRouter();

const casesResponse: Ref<SuccessResponse<CaseRecord[]>> = ref({
  status: 'success',
  data: [],
  meta: {},
});

const loading = ref(false);

const queryBuilder: Ref<QueryBuilderOptions> = ref({
  filters: {
    status: 'open,service-call,review-call',
  },
  includes: ['client', 'client.address', 'legalArea', 'caseType'],
  fields: {
    case_records: [
      'id',
      'client_id',
      'status',
      'created_at',
      'legal_area_id',
      'case_type_id',
    ] as Fieldset<CaseRecord>,
    clients: [
      'id',
      'first_name',
      'last_name',
      'is_company',
      'phone_number',
    ] as Fieldset<Client>,
  },
  sorts: ['status'],
  pagination: {
    page: 1,
    perPage: 10,
  },
});

const getCases = async () => {
  loading.value = true;

  const response = await briefcase.cases.index(queryBuilder.value);

  if (response.status === 'success') {
    casesResponse.value = response;
  }

  loading.value = false;
};
onMounted(() => {
  getCases();
});
</script>

<template>
  <div class="page-content">
    <div class="flex flex-row items-center justify-between">
      <div>
        <h1 class="font-semibold">Takenlijst</h1>
      </div>

      <div class="flex flex-row">
        <VButton @click="router.push('/klanten/nieuw')">
          <PencilSquareIcon class="h-4 w-4" />
          Nieuwe Case
        </VButton>
      </div>
    </div>

    <div class="mt-5 w-full">
      <Card>
        <template #main>
          <DynamicTable
            :response="casesResponse"
            v-model:query="queryBuilder"
            :title="{ single: 'Taak', plural: 'Taken' }"
            row-height="77px"
            :columns="[
              {
                name: 'Telefoonnummer',
                key: 'client',
                sortable: false,
                width: 'w-1/6',
                component: () =>
                  import('../Components/molecules/columns/PhoneColumn.vue'),
              },
              {
                name: 'Case',
                key: 'status',
                sortable: true,
                width: 'w-1/3',
                slotName: 'CaseColumn',
              },
              {
                name: 'Klant',
                key: 'client',
                component: () =>
                  import('../Components/molecules/columns/ClientColumn.vue'),
                width: 'w-1/3',
              },
              {
                name: 'Binnengekomen',
                key: 'created_at',
                sortable: true,
                width: 'w-1/12',
                component: () =>
                  import('../Components/molecules/columns/DateTimeColumn.vue'),
              },
              {
                name: '',
                key: 'id',
                sortable: false,
                width: 'w-1/12',
                slotName: 'ActionsColumn',
              },
            ]"
            :loading="loading"
            @fetch="getCases"
          >
            <template #CaseColumn="{ data, item }">
              <CaseColumn :item="item" :data="data" />
            </template>
            <template #ActionsColumn="{ item }">
              <div class="flex flex-col items-center">
                <button
                  class="aspect-square p-1 text-neutral-300 transition-all duration-75 ease-in-out hover:text-neutral-900"
                  @click="
                    router.push(
                      `/klanten/${item.client_id.replace('client_', '')}/cases/${item.id.replace('case_', '')}`,
                    )
                  "
                >
                  <ChevronRightIcon class="h-6" />
                </button>
              </div>
            </template>
          </DynamicTable>
        </template>
      </Card>
    </div>
  </div>
</template>
