<script setup lang="ts">
import LoginForm from '../Components/molecules/LoginForm.vue';
import { definePage } from 'unplugin-vue-router/runtime';

definePage({
  meta: {
    hideSidebar: true,
    title: 'Login',
  },
});
</script>

<template>
  <div class="flex h-[100vh] w-full flex-grow-[1] items-center justify-center">
    <LoginForm />
  </div>
</template>
