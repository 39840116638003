const pageTitle = (title: string | null | undefined) => {
  const result = title ? `${title} - Briefcase` : 'Briefcase';

  const envPrefixes = {
    development: '[DEV] ',
    staging: '[STG] ',
  };

  const envPrefix = envPrefixes[import.meta.env.MODE] || '';

  return `${envPrefix}${result}`;
};

export default pageTitle;
