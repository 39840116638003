<script lang="ts">
import { useLegalAssociations } from '../../../../Loaders/legalAssociations';
export { useLegalAssociations };
</script>

<script setup lang="ts">
import { computed, nextTick, onBeforeMount, ref } from 'vue';
import useBriefcase from '../../../../Helpers/useBriefcase';
import { useRoute, useRouter } from 'vue-router';
import {
  Card,
  Divider,
  type DropdownItem,
  DropdownMenu,
  ImageUploader,
  VButton,
  VInput,
  DatePicker,
  TextEditor,
  MultiSelect,
} from '@briefcase-app/briefcase-lib';
import {
  UserIcon,
  TrashIcon,
  AtSymbolIcon,
  PhoneIcon,
} from '@heroicons/vue/24/outline';
import { ChevronLeftIcon, EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
import ConfirmationModal from '../../../../Components/molecules/modals/ConfirmationModal.vue';
import type { Image } from '@briefcase-app/briefcase-api-sdk';
import useSharedStore from '../../../../Stores/SharedStore';
import { CheckIcon } from '@heroicons/vue/16/solid';

definePage({
  meta: {
    title: 'Advocaat',
  },
});

const { data: legalAssociations } = useLegalAssociations();
const briefcase = useBriefcase();
const route = useRoute(
  '/kantoren/[legalCompanyId]/advocaten/[legalEmployeeId]',
);
const router = useRouter();
const sharedStore = useSharedStore();

const legalEmployee = ref(null);
const originalLegalEmployee = ref(null);

const legalEmployeeId = computed(() =>
  route.params.legalEmployeeId.replace('emp_', ''),
);

const legalAreaOptions = computed(() =>
  sharedStore.parentLegalAreasWithChildren.map((area) => ({
    value: area.id,
    label: area.name,
  })),
);

const legalAssociationOptions = computed(() =>
  legalAssociations.value.map((association) => ({
    value: association.id,
    label: association.name,
  })),
);

const fetchLegalEmployee = async () => {
  const response = await briefcase.legalEmployees.show(
    `emp_${legalEmployeeId.value}`,
    { includes: ['legalCompany'] },
  );

  if (response.status === 'success') {
    legalEmployee.value = response.data;
    originalLegalEmployee.value = JSON.stringify(response.data);
  }
};

const watchables = [
  'initials',
  'first_name',
  'last_name',
  'experience_since',
  'email',
  'phone',
  'about',
  'legal_areas',
  'legal_associations',
];

const legalEmployeeHasChanged = computed(() => {
  if (!legalEmployee.value) return false;

  return watchables.some(
    (watchable) =>
      JSON.stringify(legalEmployee.value[watchable]) !==
      JSON.stringify(JSON.parse(originalLegalEmployee.value)[watchable]),
  );
});

const deleteConfirmationIsOpen = ref(false);
const deletingLegalEmployee = ref(false);

const deleteLegalEmployee = async () => {
  deletingLegalEmployee.value = true;

  await briefcase.legalEmployees.destroy(legalEmployee.value.id);

  deleteConfirmationIsOpen.value = false;
  deletingLegalEmployee.value = false;

  await router.push(`/kantoren/${route.params.legalCompanyId}`);
};

const dropdownItems = computed<DropdownItem[]>(() => {
  return [
    {
      icon: TrashIcon,
      label: 'Verwijderen',
      divider: 'top',
      style: 'error',
      onClick: () => {
        deleteConfirmationIsOpen.value = true;
      },
    },
  ];
});

const updatePhoto = (image: Image | null) => {
  briefcase.legalEmployees.update(legalEmployee.value.id, {
    photo_id: image?.id ?? null,
  });
};

const isUpdating = ref(false);

const updateLegalEmployee = async () => {
  isUpdating.value = true;

  const data: { [key: string]: unknown } = {};

  watchables.forEach((key) => {
    data[key] = legalEmployee.value[key];
  });

  const response = await briefcase.legalEmployees.update(
    legalEmployee.value.id,
    data,
  );

  if (response.status === 'success')
    originalLegalEmployee.value = JSON.stringify(response.data);

  await nextTick(() => {
    isUpdating.value = false;
  });
};

onBeforeMount(async () => {
  await fetchLegalEmployee();
});
</script>

<template>
  <div class="page-content" v-if="legalEmployee">
    <div class="mb-4 flex select-none flex-row items-center gap-2 text-center">
      <RouterLink :to="`/kantoren/${route.params.legalCompanyId}`">
        <p
          class="flex items-center text-sm font-medium text-neutral-400 transition-all hover:text-neutral-900"
        >
          <ChevronLeftIcon class="mr-0.5 h-4" />
          Terug naar alle advocaten
        </p>
      </RouterLink>
    </div>

    <Card>
      <template #header>
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center gap-2">
            <UserIcon class="h-5" />

            <h1 class="text-lg font-bold">Advocaat</h1>
          </div>

          <DropdownMenu :items="dropdownItems" :icon="EllipsisVerticalIcon" />
        </div>
      </template>

      <template #main>
        <div class="flex flex-col gap-2">
          <div class="grid grid-cols-2 xl:grid-cols-3">
            <ImageUploader
              v-model="legalEmployee.photo"
              :briefcase
              :imageableType="'App\\Models\\LegalEmployee'"
              :imageableId="legalEmployee.id"
              @save="updatePhoto"
              label="Profielfoto"
            />
          </div>

          <div class="mt-4 flex flex-col gap-4">
            <div class="grid grid-cols-5 gap-4">
              <VInput
                label="Voorletters"
                name="initials"
                v-model="legalEmployee.initials"
                class="col-span-1"
              />
              <VInput
                label="Voornaam"
                name="fname"
                v-model="legalEmployee.first_name"
                class="col-span-2"
              />
              <VInput
                label="Achternaam"
                name="lname"
                v-model="legalEmployee.last_name"
                class="col-span-2"
              />
            </div>

            <DatePicker
              label="Ervaring sinds"
              v-model="legalEmployee.experience_since"
            />

            <div class="grid grid-cols-2 gap-4">
              <VInput
                label="Email"
                v-model="legalEmployee.email"
                :icon="AtSymbolIcon"
              />
              <VInput
                label="Telefoon"
                v-model="legalEmployee.phone"
                :icon="PhoneIcon"
              />
            </div>
          </div>

          <Divider />

          <div class="flex flex-col gap-4">
            <div class="flex flex-col">
              <MultiSelect
                label="Rechtsgebieden"
                v-model="legalEmployee.legal_areas"
                :options="legalAreaOptions"
              />
            </div>

            <div class="flex flex-col">
              <MultiSelect
                label="Lidmaatschappen"
                v-model="legalEmployee.legal_associations"
                :options="legalAssociationOptions"
              />
            </div>
          </div>

          <Divider />

          <TextEditor
            :label="`Over deze advocaat`"
            v-model="legalEmployee.about"
          />

          <Divider />

          <div class="flex justify-end">
            <VButton
              :style="'neutral'"
              @click="updateLegalEmployee"
              :loading="isUpdating"
              :disabled="!legalEmployeeHasChanged"
              :rightIcon="CheckIcon"
            >
              <p>Opslaan</p>
            </VButton>
          </div>
        </div>
      </template>
    </Card>

    <ConfirmationModal
      title="Advocaat verwijderen?"
      :is-open="deleteConfirmationIsOpen"
      @cancel="deleteConfirmationIsOpen = false"
      @confirm="deleteLegalEmployee"
      :is-loading="deletingLegalEmployee"
    >
      Weet je zeker dat je
      {{ `${legalEmployee.first_name} ${legalEmployee.last_name}` }} en alle
      bijbehorende data wilt verwijderen?
      <br />
      <span class="font-medium">
        Deze actie kan niet ongedaan gemaakt worden!
      </span>
    </ConfirmationModal>
  </div>
</template>
