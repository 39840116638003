<script setup lang="ts">
import { type RouteLocationNormalized, RouterLink } from 'vue-router';
import type { RouteNamedMap } from 'vue-router/vue-router-auto';

const props = withDefaults(
  defineProps<{
    to: RouteNamedMap | string;
    name: string;
    checkStrictPath?: boolean;
  }>(),
  {
    checkStrictPath: false,
  },
);

const checkPath = (route: RouteLocationNormalized) => {
  if (props.checkStrictPath) {
    return route.fullPath === props.to;
  } else {
    return route.path.includes(props.to.toString());
  }
};
</script>

<template>
  <RouterLink
    :to="to"
    :class="checkPath($route) ? 'bg-white text-black' : 'text-white'"
    class="flex flex-row items-center gap-4 rounded-md px-2 py-2 transition-all hover:bg-white hover:text-black"
  >
    <slot name="icon"></slot>
    {{ name }}
  </RouterLink>
</template>
